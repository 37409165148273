import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Flex,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo } from "react";
import CalendarNoteEditor from "./CalendarNoteEditor";
import { useInstruments } from "../../hooks";
import { Trade, TradeEx } from "../../types";
import PositionsAgGrid from "../../features/positions/tradesview/components/PositionsAgGrid";
import TradesAgGrid from "../../features/positions/tradesview/components/TradesAgGrid";
import { NotesTimeline } from "../timeline/NotesTimeline";

export function CalendarNotesModal({
  isOpen,
  onClose,
  date,
  note,
  expiries,
  trades,
}: {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  note: {
    content: string;
    date: Date;
  };
  expiries: Trade[];
  trades: Trade[];
}) {
  const instruments = useInstruments();

  // Map the trades array to include `instrument` and `underlyingInstrument`
  const updatedTrades: TradeEx[] = useMemo(() => {
    return trades.map((trade: Trade) => ({
      ...trade,
      instrument: trade.instrumentId
        ? instruments.instruments.find((x) => x.id === trade.instrumentId)
        : undefined,
      underlyingInstrument: trade.underlyingInstrumentId
        ? instruments.instruments.find(
            (x) => x.id === trade.underlyingInstrumentId
          )
        : instruments.instruments.find((x) => x.id === trade.instrumentId),
    }));
  }, [trades, instruments.instruments]);

  // Map the expiries array to include `instrument` and `underlyingInstrument`
  const updatedExpiries: TradeEx[] = useMemo(() => {
    return expiries.map((trade: Trade) => ({
      ...trade,
      instrument: trade.instrumentId
        ? instruments.instruments.find((x) => x.id === trade.instrumentId)
        : undefined,
      underlyingInstrument: trade.underlyingInstrumentId
        ? instruments.instruments.find(
            (x) => x.id === trade.underlyingInstrumentId
          )
        : instruments.instruments.find((x) => x.id === trade.instrumentId),
    }));
  }, [expiries, instruments.instruments]);

  return (
    <Modal isOpen={isOpen} size="full" onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg="var(--bg)">
        <ModalHeader p={0} pl={6} mt={4}>
          {moment(date).format("Do MMMM YYYY")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Using Tabs to switch between sections */}
          <Tabs>
            <TabList>
              <Tab>Notes</Tab>
              {updatedExpiries.length > 0 && <Tab>Upcoming Expiries</Tab>}
              {updatedTrades.length > 0 && <Tab>Trades</Tab>}
              {updatedTrades.length > 0 && <Tab>Timeline</Tab>}
            </TabList>

            <TabPanels>
              <TabPanel>
                <CalendarNoteEditor note={note} menuBarEnabled={true} />
              </TabPanel>

              {updatedExpiries.length > 0 && (
                <TabPanel>
                  <Heading mb="10px" size="sm">
                    Upcoming expiries on open positions
                  </Heading>
                  <PositionsAgGrid trades={updatedExpiries} />
                </TabPanel>
              )}

              {updatedTrades.length > 0 && (
                <TabPanel>
                  <Heading mb="10px" size="sm">
                    Trades for this day
                  </Heading>
                  <TradesAgGrid trades={updatedTrades} />
                </TabPanel>
              )}

              {updatedTrades.length > 0 && (
                <TabPanel>
                  <NotesTimeline trades={updatedTrades} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
